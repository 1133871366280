import React, { Component } from "react";
import '../SongList/SongList.css';

 
class SongList extends Component {
  constructor() {
    super();
    this._handleDelete = this._handleDelete.bind(this);
    this.addSongs = this.addSongs.bind(this);
  }

  _handleDelete(id){
        this.props._handleDelete(id);
  }

  addSongs(item) {
    return (
      <li key={item.id}> {/* Move the <li> tag outside of <ol> */}
        <span className="songInfo"> {/* Wrap text in a span for better control */}
          <b>{item.trackname}</b> {item.artists}
        </span>
        <button className="deleteButton" value={item.id} onClick={() => this._handleDelete(item.key)}>
          REMOVE
        </button> 
      </li>
    )
  }
 
  render() {
    var songEntries = this.props.entries;
    var listItems = songEntries.map(this.addSongs);
  
    return (
      <ol className={this.props.style}>
        {listItems}
      </ol> 
    );
  }  
};
 
export default SongList;