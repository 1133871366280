import React, { useEffect } from 'react';
import Home from './components/Home/Home';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'; // Import createBrowserHistory
import ReactGA from 'react-ga4'; // Import ReactGA for Google Analytics 4

// Create a custom history object
const customHistory = createBrowserHistory();

// Initialize Google Analytics 4
const TRACKING_ID = "G-RQR7G9GH73"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    // Track initial page load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    // Track page views on route change
    customHistory.listen((location) => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    });
  }, []);

  return (
    <Router history={customHistory}> {/* Use customHistory instead of history */}
      <Route exact path="/" component={Home} />
    </Router>
  );
}

export default App;
